import { getDeviceSize } from '~/utils/device'
import debounce from 'lodash.debounce'

export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    title: {
      type: String,
    },
    data: {
      type: [Array, Object],
      required: true,
    },
    withoutTitle: {
      type: Boolean,
      default: false,
    },
    dataAttributes: {
      type: [Array, Object],
    },
  },

  data() {
    return {
      items: [],
      isDesktop: null,
      hasDeviceSize: false,
    }
  },

  created() {
    this.items = this.data
  },

  computed: {
    // hasDeviceSize() {
    //   return this.isDesktop !== null
    // },
    isWithoutTitle() {
      return this.withoutTitle
    },

    hasTitle() {
      return this.title ? true : false
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resize)

      window.dispatchEvent(new Event('resize'))
      this.checkDeviceSize()
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },

  methods: {
    checkDeviceSize() {
      const device = getDeviceSize()
      if (/desktop(?:\-(?:xl|[lm]))?/.test(device)) {
        this.isDesktop = true
        this.hasDeviceSize = true
      } else {
        this.isDesktop = false
        this.hasDeviceSize = true
      }
    },

    resize(e) {
      this.resizeDebounce(e, this)
    },

    resizeDebounce: debounce((e, self) => {
      const deviceSize = getDeviceSize()

      if (/desktop(?:\-(?:xl|[lm]))?/.test(deviceSize)) {
        self.isDesktop = true
      } else {
        self.isDesktop = false
      }
    }, 100),

    toggleActive(index) {
      this.items.map((item, i) => {
        index === i ? (item.active = true) : (item.active = false)
      })
    },
  },
}
